import React from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../../RadioUI/CustomButton'
import Text from '../../RadioUI/Text'
import Icon from '../../RadioUI/Icon'

const FavouriteButton = ({
  onClick,
  loading,
  isFavourite,
  followingText = 'Følger',
  notFollowingText = 'Følg',
  showText = true,
  variant,
  ...restProps
}) => {
  const iconId = isFavourite ? 'nrk-star--active' : 'nrk-star'
  const buttonText = isFavourite ? followingText : notFollowingText

  return (
    <CustomButton
      data-test={buttonText}
      aria-label={buttonText}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      leftIcon={iconId}
      variant={variant}
      disabled={loading}
      onClick={onClick}
      size="md"
      px={{ base: '8px', sm: '10px' }}
      {...restProps}
    >
      <Icon pl={{ base: '0', sm: '4px' }} iconId={iconId} />{' '}
      {showText && (
        <Text pl={restProps.flexDir === 'row-reverse' ? '0' : '2'} pr="8px" className="FavouriteButton__text">
          {buttonText}
        </Text>
      )}
    </CustomButton>
  )
}

FavouriteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  isFavourite: PropTypes.bool,
  followingText: PropTypes.string,
  notFollowingText: PropTypes.string,
  showText: PropTypes.bool,
  variant: PropTypes.string
}

export default FavouriteButton
