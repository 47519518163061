import { useState, useEffect } from 'react'

export const SHARE_TYPES = {
  EXEC_COMMAND: 'EXEC_COMMAND',
  SHARE: 'SHARE',
  CLIPBOARD_WRITE_TEXT: 'CLIPBOARD_WRITE_TEXT',
  MANUAL: 'MANUAL' // None of the other methods work. Can't feature-detect exexCommand('copy) unfortunately.
}

const useShareTypeDetection = (skipShareIfClipboard = false) => {
  const [supportedShareType, setSupportedShareType] = useState(SHARE_TYPES.MANUAL)

  useEffect(() => {
    const supportShareApi = 'share' in navigator
    const supportsClipboardWritText = 'clipboard' in navigator && 'writeText' in navigator.clipboard
    const supportsExecCommand = 'execCommand' in document

    if (skipShareIfClipboard && supportsClipboardWritText) {
      setSupportedShareType(SHARE_TYPES.CLIPBOARD_WRITE_TEXT)
    } else if (skipShareIfClipboard && supportsExecCommand) {
      setSupportedShareType(SHARE_TYPES.EXEC_COMMAND)
    } else if (supportShareApi) {
      setSupportedShareType(SHARE_TYPES.SHARE)
    } else if (supportsClipboardWritText) {
      setSupportedShareType(SHARE_TYPES.CLIPBOARD_WRITE_TEXT)
    } else if (supportsExecCommand) {
      setSupportedShareType(SHARE_TYPES.EXEC_COMMAND)
    }
  }, [skipShareIfClipboard])

  return supportedShareType
}

export default useShareTypeDetection
