import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import PseudoBox from '../PseudoBox'

const NativeImage = forwardRef(({ htmlWidth, htmlHeight, alt = '', ...props }, ref) => {
  return <img width={htmlWidth} height={htmlHeight} ref={ref} alt={alt} {...props} />
})

NativeImage.displayName = 'NativeImage'

NativeImage.propTypes = {
  htmlHeight: PropTypes.string,
  htmlWidth: PropTypes.string,
  alt: PropTypes.string
}

const Image = forwardRef(({ src, srcSet, useIsInView = false, ...props }, ref) => {
  if (!useIsInView) {
    return <PseudoBox as={NativeImage} src={src} srcSet={srcSet} {...props} />
  }
  return (
    <InView triggerOnce threshold={0}>
      {({ inView, ref: inViewRef }) => {
        const setRefs = node => {
          if (ref) {
            ref.current = node
          }
          inViewRef(node)
        }
        if (!inView) {
          return <PseudoBox h="100%" ref={setRefs} {...props} />
        }
        return <PseudoBox as={NativeImage} ref={setRefs} src={src} srcSet={srcSet} {...props} />
      }}
    </InView>
  )
})

Image.displayName = 'Image'

Image.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  useIsInView: PropTypes.bool
}

export default Image
