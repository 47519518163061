import { useState, useEffect, useCallback } from 'react'
import logger from '../../../logger'

export const COPY_STATES = {
  INITIAL: 'INITIAL',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

const useCopiedState = () => {
  const [state, setState] = useState(COPY_STATES.INITIAL)

  useEffect(() => {
    if (state === COPY_STATES.SUCCESS) {
      const timeout = setTimeout(() => {
        setState(COPY_STATES.INITIAL)
      }, 1000)

      return () => clearTimeout(timeout)
    }
  }, [state])

  const setCopiedState = useCallback(copyState => {
    if (Object.values(COPY_STATES).includes(copyState)) {
      setState(copyState)
    } else {
      logger.error('Unknown copy state: ', copyState)
    }
  }, [])

  return [state, setCopiedState, COPY_STATES]
}

export default useCopiedState
