import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../RadioUI/Icon'
import PseudoBox from '../RadioUI/PseudoBox'
import { css } from '@emotion/react'

const Checkbox = ({ children, checked, setChecked, name, ...restProps }) => {
  return (
    <>
      <PseudoBox
        as="label"
        {...restProps}
        css={css(`
      input:focus-visible + span {
        outline: solid 1px white
      }
      `)}
        _hover={{ cursor: 'pointer' }}
      >
        {children}
        <input
          type="checkbox"
          checked={checked}
          onChange={() => {
            setChecked(!checked)
          }}
          style={{ opacity: '0', position: 'absolute' }}
          name={name}
        />
        <Icon
          iconId={checked ? 'nrk-checkbox--active' : 'nrk-checkbox'}
          fontSize="12px"
          borderRadius="3px"
          aria-hidden="true"
        />
      </PseudoBox>
    </>
  )
}

Checkbox.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  name: PropTypes.string
}

export default Checkbox
