import { css } from '@emotion/react'

const modalStyle = css`
  &[hidden] {
    visibility: hidden;
    display: block !important;
    opacity: 0;
    transform: translate(-50%, 0);
  }
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform, opacity;
  transition: opacity 0.2s cubic-bezier(0.12, 0, 0, 1), transform 0.1s linear;
  opacity: 1;
  z-index: 1401;
`

export default modalStyle
