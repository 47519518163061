import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIsLoggedIn } from '../Auth/AuthContext'
import { addFavourite, checkFavourite, removeFavourite } from '../../apiClients/personalisation'
import logger from '../../logger'

const noop = () => {}

export const useFavourite = ({
  contentId,
  favouriteContentType,
  autoUpdate = true,
  onFavourite = noop,
  onUnFavourite = noop
}) => {
  const isLoggedIn = useIsLoggedIn()
  const [isLoading, setIsLoading] = useState(false)
  const [favouriteResponse, setFavouriteResponse] = useState(null)

  const refreshFavourite = useCallback(() => {
    if (isLoggedIn) {
      setIsLoading(true)
      checkFavourite(contentId, favouriteContentType)
        .then(favouriteResponse => {
          setFavouriteResponse(favouriteResponse)
          setIsLoading(false)
        })
        .catch(error => {
          if (error.status === 404) {
            setFavouriteResponse(null)
          } else {
            logger.error(error)
          }
          setIsLoading(false)
        })
    }
  }, [contentId, favouriteContentType, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && autoUpdate) {
      refreshFavourite()
    }
  }, [autoUpdate, isLoggedIn, refreshFavourite])

  const toggleFavourite = useCallback(async () => {
    if (!isLoggedIn) {
      logger.warn('User is not logged in.')
      return
    }
    try {
      setIsLoading(true)

      if (favouriteResponse?._links?.deleteFavourite?.href) {
        await removeFavourite(favouriteResponse?._links?.deleteFavourite?.href)
        setFavouriteResponse(null)
        onUnFavourite()
      } else {
        const addData = await addFavourite(contentId, favouriteContentType)
        setFavouriteResponse(addData)
        onFavourite()
      }
      setIsLoading(false)
    } catch (error) {
      logger.error(error)
      setIsLoading(false)
    }
  }, [
    contentId,
    favouriteContentType,
    favouriteResponse?._links?.deleteFavourite?.href,
    isLoggedIn,
    onFavourite,
    onUnFavourite
  ])

  const value = useMemo(() => {
    return {
      isLoading,
      isFavourite: !!favouriteResponse,
      toggleFavourite,
      refreshFavourite
    }
  }, [favouriteResponse, isLoading, refreshFavourite, toggleFavourite])

  return value
}
