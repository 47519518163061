import { COPY_STATES } from './useCopiedState'
import { SHARE_TYPES } from './useShareTypeDetection'
import { useCallback } from 'react'

const useHandleShare = ({ timeStamp, onShare, setCopiedState, shareUrl, timestampUsed, urlInputRef, shareType }) => {
  const handleShare = useCallback(() => {
    onShare({ timestampUsed, timeStamp })
    switch (shareType) {
      case SHARE_TYPES.SHARE: {
        return navigator.share({ url: shareUrl })
      }
      case SHARE_TYPES.CLIPBOARD_WRITE_TEXT: {
        return navigator.clipboard.writeText(shareUrl).then(setCopiedState(COPY_STATES.SUCCESS))
      }
      case SHARE_TYPES.EXEC_COMMAND: {
        urlInputRef.current.select()
        const didCopy = document.execCommand('copy')
        if (didCopy) {
          return setCopiedState(COPY_STATES.SUCCESS)
        }
        return setCopiedState(COPY_STATES.FAILURE)
      }
      default: {
        setCopiedState(COPY_STATES.FAILURE)
      }
    }
  }, [onShare, setCopiedState, shareType, shareUrl, timeStamp, timestampUsed, urlInputRef])

  return handleShare
}

export default useHandleShare
