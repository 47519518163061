import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import CoreDialog from '@nrk/core-dialog/jsx'
import CustomButton from '../CustomButton'
import Flex from '../Flex'
import Icon from '../Icon'
import Portal from '../Portal'
import modalStyle from '../Portal/modalStyle'
import useHandleShare from './hooks/useHandleShare'
import useCopiedState, { COPY_STATES } from './hooks/useCopiedState'
import useShareTypeDetection, { SHARE_TYPES } from './hooks/useShareTypeDetection'
import toTimestamp from './helpers/toTimestamp'
import toTime from './helpers/toTime'
import toUrlTimestamp from './helpers/toUrlTimestamp'
import Box from '../Box'
import Text from '../Text'
import IconButton from '../IconButton'
import { useColors } from '../theme/Color'
import PseudoBox from '../PseudoBox'
import Checkbox from '../../components/Checkbox'

const noop = () => {}

const DialogWrapper = ({ appendToRoot = true, children }) => {
  return appendToRoot ? <Portal>{children}</Portal> : children
}

DialogWrapper.propTypes = {
  appendToRoot: PropTypes.bool,
  children: PropTypes.node
}

const ShareComponent = ({
  url,
  isHiddenState,
  setIsHiddenState,
  modalHeader = 'Del lenke',
  onShare = noop,
  onDialogToggle = noop,
  onClick = noop,
  children,
  timestampSeconds,
  isMobile = false,
  appendToRoot = true,
  ...restProps
}) => {
  const [copiedState, setCopiedState] = useCopiedState()
  const [timestampUsed, setUseTimestamp] = useState(false)
  const urlInputRef = useRef()
  const shareType = useShareTypeDetection(!isMobile)
  const shareUrl = timestampSeconds > 0 && timestampUsed ? `${url}#t=${toUrlTimestamp(toTime(timestampSeconds))}` : url
  const timestamp = toTimestamp(toTime(timestampSeconds))
  const colors = useColors()

  const handleShareCopy = useHandleShare({
    timestamp,
    onShare,
    setCopiedState,
    shareUrl,
    timestampUsed,
    urlInputRef,
    shareType
  })

  if (!url) return null

  const shareCopyButtonText = shareType === SHARE_TYPES.SHARE ? 'Del' : 'Kopier'
  return (
    <>
      <Global
        styles={css`
          backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1400;
          }
        `}
      />
      <CustomButton
        px={{ base: '8px', sm: '10px' }}
        onClick={() => setIsHiddenState(!isHiddenState)}
        disabled={!url}
        {...restProps}
      >
        <Flex alignItems="center" flexDir="inherit" justifyContent="inherit">
          <Icon pl={{ base: '0', sm: '4px' }} iconId="nrk-some-share" />
          {children}
        </Flex>
      </CustomButton>
      <DialogWrapper appendToRoot={appendToRoot}>
        <CoreDialog
          className="CoreDialog"
          hidden={isHiddenState}
          onDialogToggle={event => {
            setIsHiddenState(!!event.target.hidden)
            onDialogToggle(event)
          }}
          css={modalStyle}
        >
          <Box as="form" bg={colors.main.medium} w="sm" maxW="calc(100vw - 20px)" p="6" borderRadius="md" shadow="md">
            <Box pb="5" position="relative">
              <Text textAlign="center" size="xl" weight="semibold">
                {modalHeader}
              </Text>
              <IconButton
                position="absolute"
                right="0"
                bottom="1rem"
                onClick={() => setIsHiddenState(true)}
                iconId="nrk-close"
                aria-label="Lukk"
                variant="transparent"
                data-test="closeShare"
                borderRadius="6px"
              />
            </Box>
            <label htmlFor="shareUrl" className="nrk-sr">
              URL
            </label>
            <PseudoBox
              id="shareUrl"
              as="input"
              w="full"
              ref={urlInputRef}
              data-test="shareUrl"
              type="text"
              border={`1px solid ${colors.main.contrastLight}`}
              borderRadius="2px"
              py="8px"
              px="12px"
              background={colors.main.dark}
              _focus={{
                outline: 'none',
                borderColor: colors.main.light,
                '::placeholder': { opacity: '0.7' }
              }}
              _focusVisible={{
                outline: 'none'
              }}
              onChange={() => {}}
              value={shareUrl}
              onFocus={() => {
                // Workaraound; setTimeout necessary for IOS
                setTimeout(() => {
                  urlInputRef.current.setSelectionRange(0, shareUrl.length)
                }, 1)
              }}
            />
            {timestampSeconds > 0 && (
              <Checkbox
                display="flex"
                alignItems="center"
                flexDir="row-reverse"
                justifyContent="start"
                checked={timestampUsed}
                setChecked={setUseTimestamp}
                pt="5"
              >
                <Text pl="2">Start ved {timestamp}</Text>
              </Checkbox>
            )}
            {copiedState === COPY_STATES.FAILURE && (
              <Text pt="5">Du må kopiere url-en selv pga. manglende støtte i nettleseren din</Text>
            )}
            <CustomButton
              mt="5"
              w="full"
              d="block"
              textAlign="center"
              onClick={() => {
                onClick()
                handleShareCopy()
              }}
            >
              {copiedState === COPY_STATES.SUCCESS && shareType !== SHARE_TYPES.SHARE ? (
                <Icon iconId="nrk-check" aria-label="Kopiert" />
              ) : (
                shareCopyButtonText
              )}
            </CustomButton>
          </Box>
        </CoreDialog>
      </DialogWrapper>
    </>
  )
}

ShareComponent.propTypes = {
  isHiddenState: PropTypes.bool,
  setIsHiddenState: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  onShare: PropTypes.func,
  onDialogToggle: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  url: PropTypes.string,
  modalHeader: PropTypes.string,
  timestampSeconds: PropTypes.number,
  appendToRoot: PropTypes.bool
}

export default ShareComponent
